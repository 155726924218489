@import  url(https://fonts.google.com/specimen/Space+Mono);

*{
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  font-family: 'Space Mono', monospace;
font-weight: 700;

}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.d{
  veryDarkCyan: hsl(183, 100%, 15%);
  darkGrayishCyan: hsl(186, 14%, 43%);
  grayishCyan: hsl(184, 14%, 56%);
  lightGrayishCyan: hsl(185, 41%, 84%);
  veryLightGrayishCyan: hsl(189, 41%, 97%);
  white: hsl(0, 0%, 100%);
  strongCyan: hsl(172, 67%, 45%);
}